<!--
  props: {
    subScenicId: {
      type: [String,Number],
      default() {
        return "" // 子景区id

      }
    }
  }
  @methods{
    changeCard 刷身份读书对应的产品
  }
-->
<template>
  <div class="search-box">
    <div class="search-centent">
      <div class="search-main" :class="{ 'search-index': position }">
        <div class="search-nav flex-between" v-if="$route.path === '/'">
          <div
            v-permission="'handsetVerification'"
            class="search-nav-item"
            :class="{ 'search-nav-selected': searchType === 2 }"
            :style="{
              color: searchType === 2 ? color : '',
            }"
            @click="clickSearchType(2)"
          >
            核销
          </div>
          <div
            v-permission="'handsetQuery'"
            class="search-nav-item"
            :class="{ 'search-nav-selected': searchType === 1 }"
            :style="{
              color: searchType === 1 ? color : '',
            }"
            @click="clickSearchType(1)"
          >
            查询
          </div>
          <!--查询页面不显示下单tab功能-->

          <div
            v-if="$route.path === '/' && canOrder"
            class="search-nav-item"
            :class="{ 'search-nav-selected': searchType === 3 }"
            :style="{
              color: searchType === 3 ? color : '',
            }"
            @click="clickSearchType(3)"
          >
            下单
          </div>
        </div>
        <div class="flex-between">
          <!--票号、订单号 搜索栏-->
          <van-search
            class="flex-1"
            show-action
            v-model.trim="searchNumber"
            placeholder="请输入搜索关键词"
            @keyup.enter="clickSearch"
          >
            <template #action>
              <div
                @click="clickSearch"
                class="seach-btn text-c click bold"
                :style="{
                  'background-color': $store.getters['common/color'].primary,
                }"
              >
                确定
              </div>
            </template>
          </van-search>
          <!--扫码、身份证图标-->
          <div class="flex-center pr-2x pl-1x">
            <van-icon
              v-if="searchType === 3"
              class="flex-start"
              :name="idCardIcon"
              size="30"
              color="#545454"
            />
            <van-icon
              v-else
              :name="scanIcon"
              @click="scan"
              size="23"
              color="#545454"
            />
          </div>
        </div>
      </div>
      <!-- 占位 -->
      <div v-if="position" style="height: 58px"></div>
      <div class="temporary-num" v-if="searchType === 2 && show">
        <div>
          <p class="flex-center mb-1x">
            <img :src="successType ? successIcon : errorIcon" alt="" />
            <span class="font-xl" :class="successType ? 'green' : 'error'">{{
              successType
                ? failInfos.length > 0
                  ? "部分核销成功"
                  : "核销成功"
                : "核销失败"
            }}</span>
          </p>

          <div v-show="successType === 1" class="van-content">
            <van-divider />
            <p v-for="(item, index) in checkList" :key="index">
              {{ item.productName }}
            </p>
            <template v-if="failInfos">
              <p v-for="(item, index) in failInfos" :key="index">
                {{ item.id }} ({{ item.msg }})
              </p>
            </template>
          </div>
          <div>{{ message }}</div>
        </div>
      </div>
    </div>
    <div class="temporary-nums flex-between" v-if="searchType === 2">
      <p>临时计数：{{ newTemporaryNum }}</p>
      <p class="primary" @click="clean">清零</p>
    </div>
    <!-- 批量核销弹窗 -->
    <van-dialog
      v-model="showVerificationPop"
      title="选择核销产品"
      :before-close="verificationConfirm"
      show-cancel-button
    >
      <div class="van-content">
        <van-checkbox-group v-model="checkboxValue">
          <van-checkbox
            v-for="item in checkboxList"
            :key="item.id"
            shape="square"
            :name="item.auxiliaryId"
            :checked-color="color"
            class="checkbox"
          >
            {{ item.productName }}
            <p class="ticket-no">票号：{{ item.auxiliaryId }}</p>
          </van-checkbox>
        </van-checkbox-group>
      </div>
    </van-dialog>
    <!--    核销成功,错误提示信息-->
    <!-- <van-dialog v-model="show">
      <div class="box">
        <p class="flex-center">
          <img :src="successType ? successIcon : errorIcon" alt="" />
          <span class="font-xl" :class="successType ? 'green' : 'error'">{{
            successType
              ? failInfos.length > 0
                ? "部分核销成功"
                : "核销成功"
              : "核销失败"
          }}</span>
        </p>

        <div v-show="successType === 1" class="van-content">
          <van-divider />
          <p v-for="(item, index) in checkList" :key="index">
            {{ item.productName }}
          </p>
          <template v-if="failInfos">
            <p v-for="(item, index) in failInfos" :key="index">
              {{ item.id }} ({{ item.msg }})
            </p>
          </template>
        </div>
        <div v-show="successType === 0">{{ message }}</div>
      </div>
    </van-dialog> -->
  </div>
</template>

<script>
import { orderTicketverify } from "@/api/search";
import { productWriteoffBatch, productWriteOff } from "@/api/order";
import {
  speak,
  readIdCard,
  scanQRCode,
  scanCode,
  clearReadIdCard,
  clearScanCode,
} from "../../../utils/hardware";
import { isIdcard } from "@/utils/global";
import { touristInfoBycard } from "@/api/member";
import { spuInfo } from "@/api/product";
import { fileUploadBase64 } from "@/api/upload";
import { faceAnalyzeUpload, face } from "@/api/iot";
export default {
  name: "search-index",
  props: {
    params: {
      type: Object,
      default() {
        return {
          subScenicId: "", // 子景区id
          format: "", // 业态id
        };
      },
    },
    position: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      searchType: 2, // 搜索类型 1 查询 2核销 3下单
      searchNumber: "", // 搜索框的值
      searchIcon: require("../../../assets/images/record/sousuo.png"), // 搜索图标
      scanIcon: require("../../../assets/images/record/sys.png"), // 扫码图标
      // 身份证读取
      idCardIcon: require("../../../assets/images/record/idCard.png"), // 身份证图标
      successIcon: require("../../../assets/images/record/zuhe.png"), // 核销成功图标
      errorIcon: require("../../../assets/images/record/error.png"), // 核销失败图标
      showVerificationPop: false, // 核销弹框
      checkboxValue: [], // 核销选中列表
      checkboxList: [], // 核销门票列表
      show: false, // 核销成功
      successType: 1, // 是否核销成功 1成功 0失败
      message: "", // 错误提示信息
      cardInfo: null,
      ready: false,
      info: {
        fieldType: 6, //证件类型
        idCard: "", //身份证/证件号
        name: "", //姓名
        address: "", //广东 地址
        birth: "", //生日
        sex: "", //性别(2男，1女)
        id: "", //游客类型id
        sybSystemIdProductId: "", //匹配产品id
        systemId: "", //景区id
        touristCategoryName: "", //游客类型name
        cardUrl: "", // 证件照url
        personFaceUrl: "", //人脸url
        personId: "", // 人脸id
      },
      // 身份证读取 end
      failInfos: [], // 批量核销有个别失败
      checkList: [], //核销选中回显
      newTemporaryNum: 0, // 临时计数
    };
  },

  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
    // 是否超管
    isSupperManager() {
      return this.$store.getters["common/isSupperManager"];
    },
    // 是否可下单
    canOrder() {
      return (
        this.$store.state.common.formatTypeList.length || this.isSupperManager
      );
    },
  },

  created() {
    const { searchNumber } = this.$route.query;
    this.searchType = this.type;
    if (searchNumber) {
      this.searchNumber = searchNumber;
      this.clickSearch();
    }
    this.getTemporaryNum();
    this.watchScan(); // 扫码
  },

  //销毁
  beforeDestroy() {
    clearReadIdCard();
    clearScanCode();
  },

  methods: {
    getTemporaryNum() {
      this.newTemporaryNum = Number(localStorage.newTemporaryNum) || 0;
    },
    clean() {
      this.newTemporaryNum = 0;
      localStorage.setItem("newTemporaryNum", 0);
    },
    watchScan() {
      readIdCard(this.scanIdCard);
      scanCode(this.scanOk);
    },
    // 扫码
    scan() {
      scanQRCode(this.scanOk);
    },
    // 扫码成功返回
    scanOk(code) {
      this.searchNumber = code;
      this.clickSearch();
    },
    // 切换搜索类型
    clickSearchType(val) {
      this.searchType = val;
      if (this.val !== 3) {
        this.watchScan(); // 扫码
      }
      //切换到身份证tab时激活
      this.$emit("changeType", val);
    },
    // 搜索
    clickSearch() {
      // 查询
      if (this.searchNumber === "") {
        this.$toast("请输入产品凭证");
        return;
      }
      // 查询
      if (this.searchType === 1) this.$emit("search-number", this.searchNumber);
      // 核销
      else if (this.searchType === 2) this.loadTicket();
      // 下单
      else {
        // this.queryTouristByIdCard();
        this.$emit("addCart", this.searchNumber); //加入购物车
      }
    },
    // 需要核销的门票列表
    loadTicket() {
      this.checkList = []; // 初始化
      this.checkboxValue = []; // 初始化
      this.failInfos = [];
      orderTicketverify({
        keyword: this.searchNumber,
      }).then((res) => {
        // 后台返回的 canUseNum和useNum不准确，注释一下逻辑， 后台判断是否能核销
        // const arr = res.filter((item) => {
        //   if (item.canUseNum !== "-1" && item.canUseNum !== item.useNum) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // });
        if (res && res.length) {
          this.checkboxList = res;
          if (res.length === 1) {
            this.getProductWriteOff(res[0].auxiliaryId);
          } else {
            this.showVerificationPop = true;
          }
          this.searchNumber = "";
        } else {
          this.searchNumber = "";
          this.successType = 0;
          // this.message = "暂无可核销产品";
          this.$toast("暂无可核销产品!");
          speak("暂无可核销产品");
        }
      });
    },
    // 单个核销
    getProductWriteOff(code) {
      productWriteOff({
        code: code,
      })
        .then((res) => {
          this.show = true;
          if (res.code !== 500) {
            this.verificaStatus = true;
            this.successType = 1;
            this.message = res.data.productName;
            // 临时计数
            this.addTemporaryNum(1);
            speak("核销成功");
          } else {
            this.verificaStatus = false;
            this.successType = 0;
            this.message = res.otherMsg;
            speak(res.msg);
          }
        })
        .catch((res) => {
          this.show = true;
          this.successType = 0;
          this.message = res.message;
          speak(res.msg ? res.msg : "核销失败");
        });
    },
    // 临时计数
    addTemporaryNum(num) {
      this.newTemporaryNum += Number(num);
      localStorage.setItem("newTemporaryNum", this.newTemporaryNum);
      this.$emit("newList");
    },
    // 核销确认按钮
    verificationConfirm(action, done) {
      if (action === "confirm") {
        let checkList = [];
        // 选中核销成功显示
        this.checkboxValue.forEach((item) => {
          let value = this.checkboxList.find((val) => {
            if (item === val.auxiliaryId) return val;
          });
          checkList.push(value);
        });
        this.checkList = checkList;
        productWriteoffBatch(
          {
            codeList: this.checkboxValue,
          },
          { catch: false }
        )
          .then((res) => {
            this.show = true;
            this.successType = 1;
            if (res.failCount !== 0) {
              if (res.successCount === 0) {
                let msg = "";
                res.failInfos.forEach((item) => {
                  msg += item.msg;
                });
                this.message = msg;
                speak("核销失败");
                this.successType = 0;
              } else {
                this.failInfos = res.failInfos;
                this.addTemporaryNum(res.successCount);
                speak("部分核销成功");
              }
            } else {
              speak("核销成功");
              // 临时计数
              this.addTemporaryNum(res.successCount);
            }
            done();
          })
          .catch((res) => {
            done(false);
            this.show = true;
            this.successType = 0;
            this.message = res.message;
            speak(res.msg ? res.msg : "核销失败");
          });
      } else {
        // 取消
        done();
      }
    },
    changeValue(value) {
      this.searchNumber = value;
    },
    // 输入框删除图标
    clearClick() {
      this.searchNumber = "";
      // 删除后自动聚焦
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    scanIdCard(res) {
      let obj = JSON.parse(res);
      if (obj) {
        this.idCard = obj.id;
        this.searchNumber = obj.id;
        //性别(2男，1女)
        if (obj.sex === "男") {
          this.info.sex = "2";
        } else {
          this.info.sex = "1";
        }
        this.info = { ...this.info, ...obj };
        this.clickSearch();
      }
    },
    //身份证获取匹配产品信息
    queryTouristByIdCard() {
      this.info.idCard = this.searchNumber;
      //身份证查游客类型
      if (!this.info.idCard) {
        this.$notify({
          type: "warning",
          message: "证件号为空",
        });
        return;
      }
      let err = isIdcard(this.info.idCard);
      if (err) {
        this.$notify({
          type: "warning",
          message: err,
        });
        return;
      }
      touristInfoBycard({
        type: 1, //1窗口  2.游小铺
        sybSystemId: this.params.subScenicId,
        idCard: this.info.idCard,
        formatType: this.params.format,
      }).then((res) => {
        if (res.length && res[0].memberTouristManagementVOList) {
          let memberTouristManagementVOList =
            res[0].memberTouristManagementVOList;
          let isDefault = memberTouristManagementVOList.find(
            (item) => item.default
          );
          if (isDefault) {
            this.info = { ...this.info, ...isDefault };
          } else if (memberTouristManagementVOList.length) {
            this.info = { ...this.info, ...memberTouristManagementVOList[0] };
          }
          //存在默认产品
          if (this.info.sybSystemIdProductId) {
            this.getProductInfo();
          } else {
            this.$notify({
              type: "warning",
              message: "暂未匹配到",
            });
          }
        } else {
          this.$notify({
            type: "warning",
            message: "暂未匹配到",
          });
        }
      });
    },
    //获取产品信息
    getProductInfo() {
      spuInfo({ skuId: this.info.sybSystemIdProductId }).then((res) => {
        //travelerFaceType 是否需要人脸 0否 1是
        if (res.travelerFaceType && this.info.photo) {
          // 刷证件拿到photo时
          //需要人脸
          this.getPicture(this.info.photo);
        }
        //出游人资料类型 travelerFiledType 0 不需要填写 1需要
        //fieldName: "证件照" fieldType: 9 id: "1380352491713789953"
        else if (
          res.travelerFiledType &&
          res.travelerFiledList &&
          res.travelerFiledList.some(
            (item) => item.id == "1380352491713789953"
          ) &&
          this.info.photo
        ) {
          //证件照 ,刷证件拿到photo时
          fileUploadBase64({ data: this.info.photo, ext: "png" })
            .then((temp) => {
              this.info.cardUrl = temp.src;
              this.changeCard();
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                type: "warning",
                message: "图片上传接口错误",
              });
            });
        } else {
          this.changeCard();
        }
      });
    },
    //图片上传
    getPicture(data64) {
      this.imgbase64 = `data:image/png;base64,${data64}`;
      faceAnalyzeUpload({ imageBase64: this.imgbase64 })
        .then((res) => {
          this.saveFace(res.src);
          this.changeCard();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //上传人脸
    saveFace(url) {
      //type：1是会员，0是员工
      let postData = { imageUrl: url, type: 1 };
      face(postData)
        .then((res) => {
          this.info.personFaceUrl = url;
          this.info.personId = res.personId;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //触发事件
    changeCard() {
      this.$emit("changeCard", this.info);
    },
    /**
     * 身份证读区对应产品区域方法_end
     */
  },
};
</script>

<style lang="less" scoped>
.search-box {
  .search-main {
    box-shadow: 0px 3px 4px 1px #e4e4e4;
    border-radius: 10px 10px 10px 10px;
    background: #ffffff;
    overflow: hidden;
    .search-nav {
      height: 50px;
      line-height: 50px;
      border-radius: 10px 10px 10px 10px;
      background: linear-gradient(180deg, #e6e6e6 0%, #ffffff 100%);
      .search-nav-item {
        flex-grow: 1;
        text-align: center;
      }
      .search-nav-selected {
        border-radius: 10px 10px 0px 0px;
        background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
        font-size: 16px;
        font-weight: bold;
      }
    }
    .vanIcon {
      position: absolute;
      right: 74px;
      color: #c0c4cc;
    }
    .disNone {
      display: none;
    }
  }
  .van-content {
    max-height: 50vh;
    overflow: auto;
    .checkbox {
      height: 55px;
      border-bottom: 1px solid #f1f1f1;
      padding: 0px 18px;
    }
  }
  .green {
    color: #28c772;
  }
  .error {
    color: #ff5353;
  }
  .seach-btn {
    /*width: 60px;*/
    padding: 0 12px;
    border-radius: 6px 6px 6px 6px;
    opacity: 0.81;
    color: #ffffff;
    line-height: 0.6rem !important;
    margin-right: 3px;
  }
  .search-centent {
    width: 94%;
    margin: 0 auto;
  }
}
.ticket-no {
  font-size: 12px;
}
.temporary-num,
.temporary-nums {
  border-radius: 8px;
  background-color: #fff;
  margin: 12px auto 0;
  overflow: hidden;
  padding: 14px;
}
.temporary-nums {
  width: 94%;
}
.search-index {
  position: fixed;
  margin-top: -50px;
  z-index: 9;
  width: 94%;
}
</style>
